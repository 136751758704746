import React, { Component } from 'react'
import './index.css'
import Particles from 'react-particles-js';
import { withRouter } from 'react-router-dom'

import particlesConfig from './data'
class Header extends Component {

    state = {
        selectedLink: '',
        showMenuIcon: false,
        showMenu: true,
    }

    componentDidMount() {
        const showParticles = this.props.showParticles
        this.setState({ selectedLink: window.location.pathname, showParticles })
        if (window.innerWidth <= 680) this.setState({ showMenuIcon: true, showMenu: false })
        window.addEventListener('resize', (e) => {
            if (e.target.innerWidth <= 680) this.setState({ showMenuIcon: true, showMenu: false })
            else this.setState({ showMenuIcon: false, showMenu: true })


        })

    }
    navigate(path) {
        this.props.history.push(path)
    }

    render() {
        const { selectedLink, showMenu, showMenuIcon } = this.state
        const { fixedHeader = true, animateHeader = '', particleHeaderClasses = '', showParticles = true, customHeaderContainerClass } = this.props

        return (
            <div className={`${fixedHeader ? `header-bg ${animateHeader}` : ''} ${customHeaderContainerClass}`}>
                {showParticles && <Particles
                    params={particlesConfig}
                    className={`particles-container  ${fixedHeader ? '' : particleHeaderClasses}`}
                />
                }
                <div className="header-container header-width">
                    <div>
                        <img className='mt-20 pointer-cursor' src={require('../../Assets/Web-logo.png')} width='160px' onClick={() => this.navigate('/')} alt='Web Logo' />
                        {showMenuIcon && <i className="fa fa-bars menu-icon mt-20 pointer-cursor" onClick={() => this.setState({ showMenu: !showMenu })}></i>}
                    </div>
                    {showMenu &&
                        <div className="links-container">
                            <ul className='header-ul links-left'>
                                <li onClick={() => this.navigate('/')} className={`${selectedLink === '/' ? 'left-link-selected' : ''}`}>Home</li>
                                <li onClick={() => this.navigate('portfolio')} className={`${selectedLink === '/portfolio' || selectedLink === '/singleportfolio' ? 'left-link-selected' : ''}`}>Portfolio</li>
                                <li onClick={() => this.navigate('services')} className={`${selectedLink === '/services' || selectedLink === '/singleservice' ? 'left-link-selected' : ''}`}>Services</li>
                                <li onClick={() => this.navigate('whoarewe')} className={`${selectedLink === '/whoarewe' ? 'left-link-selected' : ''}`}>Who are we</li>
                                <li onClick={() => this.navigate('contactus')} className={`${selectedLink === '/contactus' ? 'left-link-selected' : ''}`}>Contact</li>
                            </ul>
                            <div className='header-ul links-right'>
                                <a href='https://www.facebook.com/BrorTech-100209624724150/' target='_blank' rel="noopener noreferrer"> <i className="fa fa-facebook"></i>   </a>
                                <a href="https://twitter.com/BrorTech" target="_blank" rel="noopener noreferrer"> <i className="fa fa-twitter"></i>   </a>
                                {/* <a> <i className="fa fa-instagram"></i>   </a> */}
                                <a href="https://join.skype.com/invite/dj4vckwMCtQe" target="_blank" rel="noopener noreferrer"> <i className="fa fa-skype"></i>   </a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Header)
