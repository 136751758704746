import React, { Component } from 'react'
import './index.css'

import Header from '../../Components/Header/Header'
import PortfolioContainer from '../../Components/PortfolioContainer/PortfolioContainer'
import Footer from '../../Components/Footer/Footer'
import projectsArr from './data'

export default class Portfolio extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    

    render() {
        return (
            <div>
                <Header />
                <div className="Portfolio-container custom-container">
                    <div className="portfolio-heading-container">
                        <h1>Portfolio</h1>
                        <p>Some of our previous projects include...</p>
                    </div>
                    {/* <ul className='portfolio-cateogries-container'>
                        <li>ALL</li>
                        <li>MOBILE APPS</li>
                        <li>WEBSITES</li>
                        <li>CMS</li>
                        <li>OTHER</li>
                    </ul> */}
                    <div className='bottom-border-line'></div>
                </div>
                <PortfolioContainer data={projectsArr} />
                <Footer />
            </div>
        )
    }
}
