import * as firebase from 'firebase';
firebase.initializeApp({
    apiKey: "AIzaSyCz1x3asfvUpMiZ9Rdho2wi5PZzdRO8Aak",
    authDomain: "lifeline-4190d.firebaseapp.com",
    databaseURL: "https://lifeline-4190d.firebaseio.com",
    projectId: "lifeline-4190d",
    storageBucket: "lifeline-4190d.appspot.com",
    messagingSenderId: "1088987476842",
    appId: "1:1088987476842:web:402afd8301655624fceb13"
});

const db = firebase.firestore();

const addDatabase = async (collection, data) => {
    try {
        await db.collection(collection).add(data)
        return { message: 'Thank you for your message' }
    }
    catch (e) {
        throw e.message
    }
}

export default {addDatabase}