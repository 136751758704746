import React, { Component } from 'react'
import './index.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import img from '../../Assets/apple-icon.svg'
import web from '../../Assets/web.jpg'
import playstoreIcon from '../../Assets/playstore-icon.svg'
import appleIcon from '../../Assets/apple-icon.svg'
import webIcon from '../../Assets/web-icon.svg'

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
};
export default class SinglePortfolio extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { data } = this.props.location.state
        return (
            <div>
                <Header />
                <div style={{ backgroundImage: `url('${web}')` }} className='single-portfolio-bg-image'>
                    <div className='single-portfolio-bg-color' style={{backgroundColor : data.detailPageBgColor}}>
                        <div className='custom-container'>
                            <div className='single-portfolio-back-nav-container'>
                                <i className="fa fa-angle-left"></i>
                                <p onClick={() => this.props.history.push('/')}>Back To Home</p>
                            </div>

                            <div className='row'>
                                <div className='col-lg-2 col-md-3 single-portfolio-img'>
                                    <img src={img} alt='project' />
                                </div>
                                <div className='col-lg-5 col-md-9  single-portfolio-text'>
                                    <p>{data.detailPageTitle}</p>
                                    <p>{data.detailDescription1}</p>
                                    <p>{data.detailDescription2}</p>
                                    {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur</p> */}
                                    <div className="single-portfolio-links-container">
                                        {data.playStoreLink && <a className="portfolio-container-apps-links align-center" href={data.playStoreLink} target='_blank' rel="noopener noreferrer" style={{ color: 'white' }}>
                                            <img src={playstoreIcon} alt='Playstore icon' />
                                            <p>Playstore</p>
                                        </a>
                                        }
                                        {data.appStoreLink && <a className="portfolio-container-apps-links align-center" href={data.appStoreLink} target='_blank' rel="noopener noreferrer" style={{ color: 'white' }}>
                                            <img src={appleIcon} className="pl-3" alt='Appstore icon' />
                                            <p>Appstore</p>
                                        </a>
                                        }
                                        {data.websiteLink && <a className="portfolio-container-apps-links align-center" href={data.websiteLink} target='_blank' rel="noopener noreferrer" style={{ color: 'white' }}>
                                            <img src={webIcon} className="pl-3" alt='Web icon' />
                                            <p>Website</p>
                                        </a>
                                        }

                                    </div>

                                </div>
                                <div className='col-lg-5 single-portfolio-mobile-img text-right single-protfolio-hide'>
                                    <div className='video-container-single-portfolio'>
                                        <img src={require('../../Assets/Iphone-X outline.png')} alt='iPhone mockup' />
                                        <video autoplay='' muted loop>
                                            <source src={data.videoUrl} type="video/mp4" />
                                        </video>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-container'>
                    <div className='single-portfolio-middle-container'>
                        {/* <div>
                            <h2>
                                Challenge
                            </h2>
                            <p className='mx-auto'>The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.</p>
                        </div>
                        <div>
                            <h2>
                                Solution
                            </h2>
                            <p className='mx-auto'>The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.</p>
                        </div> */}
                    </div>
                    <div className="slider-main-container">
                        <Slider {...settings} ref={sliderRef => this.sliderRef = sliderRef}>
                            {data.sliderData.map((data, index) => {
                                return <div className='row slider-container' key={index}>
                                    <div className='col-sm-4'>
                                        <img src={data.image} className='slider-img' alt='Projects' />
                                    </div>
                                    <div className='col-sm-8'>
                                        {/* <h2>{data.heading}</h2> */}
                                        <p>{data.description}</p>
                                    </div>
                                </div>
                            })}
                        </Slider>
                        <div className='arrows-container'>
                            <div onClick={() => this.sliderRef.slickPrev()}>
                                <i className="fa fa-chevron-left"></i>
                            </div>
                            <div onClick={() => this.sliderRef.slickNext()}>
                                <i className="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
