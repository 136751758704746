import React, { Component } from 'react'
import './index.css'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import bgImage from '../../Assets/bgImage.png'
import friendsImage from '../../Assets/friends.png'
import { flags , teamArr } from './data'

export default class WhoAreWe extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Header />
                <div className='custom-container who-are-we-main-container'>
                    <div style={{ backgroundImage: `url('${bgImage}')` }} className='who-are-we-bg-image'>
                        <h1 className='who-are-we-bg-img-text'>Who Are We?</h1>
                    </div>
                    <h3 className='who-are-we-quote font-oswald'>"BrorTech is your global app development partner that turns your ideas into living breathing apps.  We love working with startups, we love transforming your enthusiastic ideas and passion into feature-rich, high-performing, and secure iOS app development solutions.  
We aim to provide you with unmatched user-experience, sky-rocket your user-engagement and bring your ideas to life while boosting your ROI.  
iOS represents a sizable market of over 10 million users that are target customer of almost any vertical.  We provide you with the expertise to tap that market with our full-cycle app development skills."</h3>

                    <div className='row who-are-we-detail-container'>
                        <div className='col-md-4'>
                            <h3>Mission Statement</h3>
                            {/* <img src={waveIcon} /> */}
                            <p>Discuss, examine, design, develop, test, deliver and maintain robust apps designed under industry best practices.  Generate value for your client and they'll never leave your side.</p>
                        </div>
                        {/* <div className='col-md-4'>
                            <h3>What we do?</h3>
                            <img src={waveIcon} />
                            <p>The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.</p>

                        </div> */}
                        <div className='col-md-4'>
                            <h3>Vision Statement:</h3>
                            {/* <img src={waveIcon} /> */}
                            <p>One day all business, big or small will have their own applications of information technology to provide more personal, unique and secure way of interacting with users.</p>

                        </div>

                    </div>
                    <div style={{ backgroundImage: `url('${friendsImage}')` }} className='who-are-we-friends-bg-img'></div>
                    {/* <h1 className='who-are-we-heading'>How we Became Successful?</h1> */}

                    {/* {
                        strategyData.map((val, i) => {
                            if (i % 2 == 0) {
                                return <div className='row strategy-section'>
                                    <div className='col-lg-4 strategy-details'>
                                        <div className='col-lg-2 line-container horizontal-circle'>
                                            <div className='circle'>
                                                <div className='inner-circle'></div>
                                            </div>
                                            <div className='line'></div>
                                        </div>
                                        <h3 className="strategy-heading">{val.title}</h3>
                                        <p className="strategy-paragraph">{val.description}</p>
                                    </div>
                                    <div className='col-lg-1 line-container vertical-circle'>
                                        <div className='circle'>
                                            <div className='inner-circle'></div>
                                        </div>
                                        <div className='line'></div>
                                    </div>
                                    {
                                        strategyData[i + 1] ?
                                            <div className='col-lg-4 strategy-details'>
                                                <div className='col-lg-2 line-container horizontal-circle'>
                                                    <div className='circle'>
                                                        <div className='inner-circle'></div>
                                                    </div>
                                                    <div className='line'></div>
                                                </div>
                                                <h3 className="strategy-heading">{strategyData[i + 1].title}</h3>
                                                <p className="strategy-paragraph">{strategyData[i + 1].description}</p>
                                            </div>
                                            :
                                            <div className='col-lg-4 strategy-details'></div>
                                    }
                                </div>
                            }
                        })
                    } */}
                        <h2 className='team-heading'>Meet Our Team</h2>
                    <div className='row team-info-container'>
                        {teamArr.map((data, index) => {
                            return <div className='col-lg-4 col-sm-6 col-12 team-single-container mt-5' key={index}>
                                <div>
                                    <img src={data.userPic} alt='Team Pictures' />
                                    <h4 style={{textAlign: 'center'}}>{data.name}</h4>
                                    <p>{data.profession}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <h2 className="text-center mt-5">Worked With Clients All Around The World</h2>
                <div className='homepage-flags-container'>
                    {(flags.map((val, i) =>
                        <img src={val} width="60px" key={i} alt='Flags' />
                    ))}
                </div>
                <div className='mt-5'>
                    <Footer />
                </div>
            </div>
        )
    }
}
