var servicesData = [
    {
        bgImg: require('../../Assets/web.jpg'),
        firstText: 'UI/UX Development ',
        secondText: "UI/UX Designing of iOS, Andriod Website based on client's requirements, projects needs, competitive analysis and target audience.",
        lastText: 'View Project',
        servicesDetails: [
            {
                heading: 'Identify the user need:',
                details: 'The first thing we do is identify the target user needs for their web page, device, or system.  We can do that based on market research, interviews with users.',
                // image: require('../../Assets/ServicesContent/ServerDevelopment/aws.png')
                image: require('../../Assets/ServicesContent/UI/1.png')
            },
            {
                heading: 'Competitor analysis:',
                details: 'Understanding both the nature of the users and how this affects their needs. Several tools and methods can be used in each phase. Each tool or method might produce a different type of output (UX deliverable). All the research work done in this phase and collect all the information.',
                image: require('../../Assets/ServicesContent/UI/2.png')
            },
            {
                heading: 'Wireframe:',
                details: 'Skeleton of the eventual user interface. Sketch (sometimes literally a pen and paper sketch) of the UI, conveys main features, functions and content of a user interface, without getting into the visual design in order to getting approval from client.',
                image: require('../../Assets/ServicesContent/UI/3.png')
            },
            {
                heading: 'UI Designing:',
                details: 'This phase starts with mood boarding that formulate the mood or theme which we like to convey within a project. Depending on the task at hand. Then things lie under assets Collection in which we decide all the images, icons, typeface and style guide.',
                image: require('../../Assets/ServicesContent/UI/4.jpg')
            },
            {
                heading: 'Prototyping:',
                details: "Turning ideas into concrete examples. In this phase we used to understand and see how a product or application works, what it does and how user should interact with it. They're simulations of how a finished product will work. Sketches, wireframes and mockups serve other purposes in the design and prototyping process.",
                image: require('../../Assets/ServicesContent/UI/5.png')
            },
            {
                heading: 'Testing:',
                details: 'Once we have a design that is implemented (even if only as an interactive prototype), we begin to run some evaluations of this design with real users. Evaluation can take many shapes and forms.',
                image: require('../../Assets/ServicesContent/UI/6.jpg')
            },
        ]
    },
    {
        bgImg: require('../../Assets/web.jpg'),
        firstText: 'Server Development',
        secondText: 'We provide Efficient, Scalable and flexible server development solutions on Amazon Web Services, Heroku, Digital Ocean and Google Firebase',
        lastText: 'View Project',
        servicesDetails: [
            {
                heading: 'Server Selection:',
                details: 'Server development begin with server provider selection, multiple companies provide this service like Amazon Web Services, Digital Ocean, Heroku and Google. Every company expertise in one category and other in some other there for client’s target market and number of users play vital rule to finalize one service provider.',
                // image: require('../../Assets/ServicesContent/ServerDevelopment/aws.png')
                image: require('../../Assets/ServicesContent/ServerDevelopment/1.jpg')
            },
            {
                heading: 'Database Schema Designing:',
                details: 'The database schema of a database is its structure that is designed to store all data into logically related collection which have relationship with each other’s. Design of schema depends on how client want to fetch and store data.',
                image: require('../../Assets/ServicesContent/ServerDevelopment/2.png')
            },
            {
                heading: 'Application Program Interface Development:',
                details: 'Application program interface (API) development provide a way for client to add, get, update and delete data from database. Development could be done using node.js, python or etc. Server-side languages.',
                image: require('../../Assets/ServicesContent/ServerDevelopment/3.PNG')
            }
        ]
    },
    {
        bgImg: require('../../Assets/web.jpg'),
        firstText: 'IOS Application Development ',
        secondText: 'IOS, iPad and iWatch application development using Swift utilizes map, augmented reality, machine learning kits with the realtime update.',
        lastText: 'View Project',
        servicesDetails: [
            {
                heading: 'IOS Application Design Implementation:',
                details: 'IOS Application development first phase is to develop application design using storyboard, auto layouts or SwiftUI. Responsive design to suit all screen sizes, colour theme and navigations between screens are main milestones of design implementation.',
                // image: require('../../Assets/ServicesContent/ServerDevelopment/aws.png')
                image: require('../../Assets/ServicesContent/IOS/1.png')
            },
            {
                heading: 'Functionality Development:',
                details: 'Swift language is used to develop functionalities of application which consists on integration of client side with server side, display data from database, implement fetch, put edit and delete functions of data on database and develop business logic of application.',
                image: require('../../Assets/ServicesContent/IOS/2.png')
            },
            {
                heading: 'Testing:',
                details: 'Testing is final step to make sure everything is working as per client requirements which include testing of all features on real device, check application flow, design and business logic implementations. The main purpose of this of this phase is to deliver bug free applications.',
                image: require('../../Assets/ServicesContent/IOS/3.jpg')
            },
            {
                heading: 'Deployment:',
                details: 'IOS application deployment requires Apple Developer or Enterprise Account to publish application on App store. Apple review team test each application to meet Apple Developer Guidelines and provide their feedback.',
                image: require('../../Assets/ServicesContent/IOS/4.png')
            }
        ]
    },
    {
        bgImg: require('../../Assets/web.jpg'),
        firstText: 'Android Application Development',
        secondText: 'Andriod application development using Java or Kotline and user interface development using XML.',
        lastText: 'View Project',
        servicesDetails: [
            {
                heading: 'Android Application Design Implementation:',
                details: 'Android Application development first phase is to develop application design using XML. Response design to suit all screen sizes, colour theme and navigations between screens are main milestones of design implementation.',
                // image: require('../../Assets/ServicesContent/ServerDevelopment/aws.png')
                image: require('../../Assets/ServicesContent/Android/1.png')
            },
            {
                heading: 'Functionality Development:',
                details: 'Java or Kotline language is used to develop functionalities of application which consists on integration of client side with server side, display data from database, implement fetch, put edit and delete functions of data on database and develop business logic of application.',
                image: require('../../Assets/ServicesContent/Android/2.png')
            },
            {
                heading: 'Testing:',
                details: 'Testing is final step to make sure everything is working as per client requirements which include testing of all features on real android devices, check application flow, design and business logic implementations. The main purpose of this of this phase is to deliver bug free applications.',
                image: require('../../Assets/ServicesContent/Android/3.jpg')
            },
            {
                heading: 'Deployment:',
                details: 'Android application deployment requires Google Developer Account to publish application on Play store.',
                image: require('../../Assets/ServicesContent/Android/4.png')
            }
        ]
    },
    {
        bgImg: require('../../Assets/web.jpg'),
        firstText: 'Website/Admin Panel Development ',
        secondText: 'Responsive, custom design and customer-oriented websites using React.',
        lastText: 'View Project',
        servicesDetails: [
            {
                heading: 'Website/Admin Panel Design Implementation:',
                details: 'Website/Admin Panel development first phase is to develop design using React. Responsive design to suit all screen sizes, colour theme and navigations between pages are main milestones of design implementation.',
                image: require('../../Assets/ServicesContent/Website/1.jpg')
            },
            {
                heading: 'Functionality Development:',
                details: 'JavaScript is used to develop functionalities of website/Admin panel which consists on integration of client side with server side, display data from database, implement fetch, put edit and delete functions of data on database and develop business logic of website.',
                image: require('../../Assets/ServicesContent/Website/2.jpeg')
            },
            {
                heading: 'Testing:',
                details: 'Testing is final step to make sure everything is working as per client requirements which include testing of all features on different screen sizes, check website flow, design and business logic implementations. The main purpose of this of this phase is to deliver bug free website.',
                image: require('../../Assets/ServicesContent/Website/3.png')
            },
            {
                heading: 'Deployment:',
                details: 'Deployment of Website/Admin Panel requires domain access and SSL certificates.',
                image: require('../../Assets/ServicesContent/Website/4.png')
            }
        ]

    },
    {
        bgImg: require('../../Assets/web.jpg'),
        firstText: 'Hybrid Application Development',
        secondText: 'Hybrid application development using react-native or flutter for both Andriod and iOS platforms',
        lastText: 'View Project',
        servicesDetails: [
            {
                heading: 'Hybrid Application Design Implementation:',
                details: 'Hybrid Application development first phase is to develop application design using React Native or flutter. Response design to suit all screen sizes, colour theme and navigations between screens are main milestones of design implementation.',
                image: require('../../Assets/ServicesContent/Hybrid/1.jpg')
            },
            {
                heading: 'Functionality Development:',
                details: 'JavaScript, Typescript or Dark language is used to develop functionalities of application which consists on integration of client side with server side, display data from database, implement fetch, put edit and delete functions of data on database and develop business logic of application.',
                image: require('../../Assets/ServicesContent/Android/2.png')
            },
            {
                heading: 'Testing:',
                details: 'Testing is final step to make sure everything is working as per client requirements which include testing of all features on real Android and iPhone device, check application flow, design and business logic implementations. The main purpose of this of this phase is to deliver bug free applications.',
                image: require('../../Assets/ServicesContent/Android/3.jpg')
            },
            {
                heading: 'Deployment:',
                details: 'IOS application deployment requires Apple Developer or Enterprise Account to publish application on App store and Android application deployment requires Google Developer Account to publish application on Play Store. Apple review team test each application to meet Apple Developer Guidelines and provide their feedback.',
                image: require('../../Assets/ServicesContent/Android/4.png')
            }
        ]

    },
    // {
    //     bgImg: require('../../Assets/web.jpg'),
    //     firstText: 'Circuit Schematic & PCB Design',
    //     secondText: 'Our dedicated team provide circuit schemantic & PCB solutions using Eagle CAD tool.',
    //     lastText: 'View Project'
    // },
]

export default servicesData