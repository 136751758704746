var homePageProjects = [
    {
        name: 'Courtesy Officer',
        description: 'Courtesy Officer includes an iOS application along with an admin panel on their website. This application was designed and developed for courtesy officers and apartment complexes.',
        detailPageTitle: 'What\’s Courtesy Officer:',
        detailDescription1: 'Courtesy officers application is created for apartment complexes to assist in serving their security needs. An apartment owner can create an account with their basic information, post jobs and offer discounts. Courtesy officers may create their account by sharing their expertise, department name and contact details. Apartment owners can schedule interviews with suitable candidates and reach out to applicants.',
        logoImage: require('../../Assets/courtesy/logo.png'),
        waveIconImage: require('../../Assets/courtesy/black.png'),
        appStoreLink: 'https://apps.apple.com/us/app/courtesy-officer/id1511296672',
        websiteLink: 'https://www.courtesyofficerapp.com',
        bannerBackgroundColor: '#CB58ED',
        clientImageBorder: '4px solid  #CB58ED',
        sliderData: [
            {
                image: require('../../Assets/courtesy/1.png'),
                description: 'Courtesy officers can create an account using email and password and later complete profile with year of experience, department name, skills, badge number, ranks, phone number and languages they can speak.'
            },
            {
                image: require('../../Assets/courtesy/2.png'),
                description: 'Officers can look for nearby apartments. Officers can see which apartment complex has open vacancies based on different colors on the map. Officers can also search for other cities and view detailed information about other areas and job listings.'
            },
            {
                image: require('../../Assets/courtesy/3.png'),
                description: 'Apartment Management may offer free residence to officers, or percentage a discount off rent. Courtesy officers can view discount with each job description and could show their interest in the listing with a single tap.'
            },
            {
                image: require('../../Assets/courtesy/4.png'),
                description: 'Apartment Management can view list of interested courtesy officers in each job and can schedule interviews with interested candidates. Officers will receive a notification for the scheduled interview.'
            },
        ],
        videoUrl : require('../../Assets/courtesy/courtesy.mp4'),
        detailPageBgColor: '#cb58edb3'
    },
    {
        name: 'Trending',
        description: 'Trending includes an iOS and Android application, specially designed and developed for talented, skilled and supporting individuals who want to showcase their work.',
        detailPageTitle: 'What’s Trending:',
        detailDescription1: 'Trending is an application for skilled talents who want to showcase out their work to others and establish their capabilities. The purpose is to develop a platform for users to connect with new and upcoming talents, and access potential services. The collective approach of each user creates something special, where Trending showcases the potential. Users can post videos, images, and text to a timeline style feed. The Services feature allows users to list their gigs with amounts and descriptions as well as show real examples of the service',
        logoImage: require('../../Assets/Trending/TrendingLogo.png'),
        waveIconImage: require('../../Assets/Trending/Red.png'),
        appStoreLink: 'https://apps.apple.com/pk/app/trending/id1476740141',
        websiteLink: 'http://trendingapp.us/',
        bannerBackgroundColor: '#F05D5E',
        clientImageBorder: '4px solid #F05D5E',
        sliderData: [
            {
                image: require('../../Assets/Trending/1.png'),
                description: 'Trending home page consists of a user followed feed that presents a user with others posts. You are able to manuever based on users, comments, and likes. The user also has the option to report posts, and create their own posts from the bottom right.'
            },
            {
                image: require('../../Assets/Trending/2.png'),
                description: 'Trending posts screen shows an array of top posts which is trending right now based on the number of likes, comments, and shares it receives. Users can tap to view details of the post with post’s owner info, total likes and all comments on the post.'
            },
            {
                image: require('../../Assets/Trending/3.png'),
                description: 'Notifications screen lists all recent notifications received so that users can easily navigate to each notifications details and perform the desired operation from each.'
            },
            {
                image: require('../../Assets/Trending/4.png'),
                description: 'The users profile screen shows total posts, supporters, supporting, recent posts, shared posts and calendars to allow other users to know when you are busy and free for services (future feature).'
            },
            {
                image: require('../../Assets/Trending/5.png'),
                description: 'The contacts menu is added to provide the users followers with an easy experience for all contact methods. Each feature is listed to ensure easy navigation from the profile.'
            },
        ],
        videoUrl: require('../../Assets/Trending/trending.mp4'),
        detailPageBgColor: '#f54e4fb0'
    },
    {
        name: 'CEFI',
        description: 'CEFI includes the iOS application, admin panel, server development and deployment on Digital Ocean in order to move the entire company online for better services.',
        detailPageTitle: 'What’s CEFI:',
        detailDescription1: 'Commercial Equipment Financing Company, Inc. (CEFI) is an independent, full-service commercial equipment financial & leasing company committed to provide tailored financing solutions to meet the needs of growing companies.',
        detailDescription2: 'CEFI application is designed and developed for CEFI’s representatives. Their job is to create new contacts for business, meet with contacts and create an agreement with them. Each transaction has a price, status, equipment type, attached images, and list of documents.',
        // gifUrl: require('../../Assets/Trending/Trending.gif'),
        logoImage: require('../../Assets/CEFI/logo.png'),
        waveIconImage: require('../../Assets/CEFI/Green.png'),
        // appStoreLink: 'https://apps.apple.com/pk/app/trending/id1476740141',
        // websiteLink: 'http://trendingapp.us/',
        bannerBackgroundColor: '#7FB685',
        clientImageBorder: '4px solid #7FB685',
        sliderData: [
            {
                image: require('../../Assets/CEFI/1.png'),
                description: 'The home screen of CEFI is designed to show essential figures so that representatives of CEFI always know the number of the meeting left today, total contacts, total transactions, and pending documents. Representatives could easily navigate to each screen from home.'
            },
            {
                image: require('../../Assets/CEFI/2.png'),
                description: 'The contact screen is designed to list all contacts. Representatives could easily add, search and filter contacts. Contacts are combined for easy navigation and side alphabets could be used to jump on a specific group of users. Users can easily view contact details, phone number, industry type, email and transactions of contact by tapping on it.'
            },
            {
                image: require('../../Assets/CEFI/3.png'),
                description: 'A transaction is a contract which a representative could create against contact. Each transaction has a unique number, price, equipment type and list of mandatory documents. This screen lists all transactions and pending documents if necessary. The user could add, search and filter transactions easily.'
            },
            {
                image: require('../../Assets/CEFI/4.png'),
                description: 'A representative can create a meeting to meet contacts. Each meeting has a purpose, locatioon address, reminder check, time, status and report linked with it. A representative can meet the client to prospect their business or to collect pending documents.'
            },
            {
                image: require('../../Assets/CEFI/5.png'),
                description: 'Pending documents are the critical scenario of this business therefore we have created a separate screen to only list transaction that has pending documents. A representative could view total pending documents and list of transaction which has pending documents.'
            },
        ],
        videoUrl: require('../../Assets/CEFI/cefi.mp4'),
        detailPageBgColor: "#78e878ad"
    },
    {
        name: 'SipQuick',
        description: 'SipQuick includes iOS and Android applications for customers who want to order online from their nearby restaurant.',
        detailPageTitle: ' What’s SipQuick:',
        detailDescription1: 'SipQuick is an application that allows users to view multiple restaurant menu items and order them. The user could view nearby restaurants on the map and add their item to the cart, add condiments and provide comments for customization. Sip Quick accept online payment and maintain transaction of every record and order for providing the best possible experience. Users can contact the administration and share the application with their friends and family.',
        // gifUrl: require('../../Assets/Trending/Trending.gif'),
        logoImage: require('../../Assets/SipQuick/logo.png'),
        waveIconImage: require('../../Assets/SipQuick/blue.png'),
        // appStoreLink: 'https://apps.apple.com/pk/app/trending/id1476740141',
        // websiteLink: 'http://trendingapp.us/',
        bannerBackgroundColor: '#3281C7',
        clientImageBorder: '4px solid #3281C7',
        sliderData: [
            {
                image: require('../../Assets/SipQuick/1.png'),
                description: 'The home screen of SipQuick is designed and developed to show all restaurants on the app with image, name, and address. The user could search for a restaurant or switch to a map view where they could view nearby restaurants on the map.'
            },
            {
                image: require('../../Assets/SipQuick/2.png'),
                description: 'The restaurant can categories its menu into different categories with a suitable image and title.'
            },
            {
                image: require('../../Assets/SipQuick/3.png'),
                description: 'Each category could contain multiple items each with image, name, and price. The user could tap on an item to view detail descriptions, condiments and can add extra comments.'
            },
            {
                image: require('../../Assets/SipQuick/4.png'),
                description: 'Cart is designed to list all items. Users could change item quantity, option for a tip, promo code, service fee, and the total price is mention.'
            },
            {
                image: require('../../Assets/SipQuick/5.png'),
                description: 'Users can add their credit and debit cards, each transaction will be secure and made through Stripe SDK. The user can view their recent and past transactions.'
            },
            {
                image: require('../../Assets/SipQuick/6.png'),
                description: 'The menu item is designed to show all features of the app so that the user could easily navigate to them. Users could view its payment and order history, contact administrations for any issue and share this app.'
            }
        ],
        videoUrl: require('../../Assets/SipQuick/sipquick.mp4'),
        detailPageBgColor: '#3281c7bf'
    },
    {
        name: 'Merchant Nation',
        description: 'Merchant Nation includes an iOS, Android application, and admin panel design for food lovers and restaurant owners.',
        detailPageTitle: 'What’s Merchant Nation:',
        detailDescription1: 'Merchant Nation is a food ordering application which shows the menu and offers based on categories which user can easily add into the cart. The reward feature is specially designed to benefit loyal customers. They can get points and order real food through it. The gift card is one of the ways to advertise application and increase customers. Users can buy and share gift cards with friends and family through any application, the receiver of Gift can receive gift item and get gifted menu item.',
        logoImage: require('../../Assets/MerchantNation/merchant.png'),
        waveIconImage: require('../../Assets/MerchantNation/Green.png'),
        // appStoreLink: 'https://apps.apple.com/pk/app/trending/id1476740141',
        // websiteLink: 'http://trendingapp.us/',
        bannerBackgroundColor: '#7FB685',
        clientImageBorder: '4px solid #7FB685',
        sliderData: [
            {
                image: require('../../Assets/MerchantNation/1.png'),
                description: 'Merchant Nation’s dashboard screen is designed to showcase all main features of the application, the main motive is to provide easy navigations by avoiding extra details for the user. The user could navigate to Menu, Offer & Promotion, Rewards, Gifts, and Reservation.'
            },
            {
                image: require('../../Assets/MerchantNation/2.png'),
                description: 'Offer & Promotion screen list all exiting promotions with a discount on them, the user could tap on each promotion to view its details and add it to cart for an order.'
            },
            {
                image: require('../../Assets/MerchantNation/3.png'),
                description: 'Reward screen show all rewards which user can avail. Rewards are based on points which user get on each menu item. Once a user owns enough points they could avail rewards.'
            },
            {
                image: require('../../Assets/MerchantNation/4.png'),
                description: 'Users can share food gifts through Merchant Nation, gift card screen list all gift cards users could send and gift cards users already brought. Users can share the gift to their friends and family.'
            },
            {
                image: require('../../Assets/MerchantNation/5.png'),
                description: 'The side menu of Merchant Nation designs and developed to list all essential options for users. They could view their order history, bookings, rewards, cart, and other setting options.'
            },
        ],
        videoUrl : require('../../Assets/MerchantNation/merchant-nation.mp4'),
        detailPageBgColor: '#7fb685d1'
    },
    {
        name: 'Castlly',
        description: 'Castlly includes an iOS application that allows users to create creative content in augmented reality.',
        detailPageTitle: 'What’s Castlly:',
        detailDescription1: 'Castlly is a social media application which uses Apple Augmented Reality Kit to allow users to create creative content and capture video to share it with friends and family. Users can send a friend request, accept or reject a request, block or unblock another user and can send text, image and video messages to others. Users can set a timer to view a message. If the receiver viewed a message within the time then we will get coins otherwise sender will get coins.',
        logoImage: require('../../Assets/Castly/logo.png'),
        waveIconImage: require('../../Assets/MerchantNation/Green.png'),
        // appStoreLink: 'https://apps.apple.com/pk/app/trending/id1476740141',
        // websiteLink: 'http://trendingapp.us/',
        bannerBackgroundColor: '#CB58ED',
        clientImageBorder: '4px solid  #CB58ED',
        sliderData: [
            {
                image: require('../../Assets/Castly/1.png'),
                description: 'Castlly home screen is designed to use Apple augmented reality kit, user can place emoji on the real environment and resize it. Slide right and left navigate to Chat and Profile screen while the circle button starts video recording.'
            },
            {
                image: require('../../Assets/Castly/2.png'),
                description: 'Chat screen list all friends with the options to search and move to add a friend\'s screen. Users can tap on a friend\'s image to open the messages screen and the video icon starts the video call.'
            },
            {
                image: require('../../Assets/Castly/3.png'),
                description: 'The profile is designed to show user image, full name, user name, and coins. Users can navigate to add friends, added me, my friends, setting and Castlly store.'
            },
            {
                image: require('../../Assets/Castly/4.png'),
                description: 'Add Friend screen list users to send request, user can search for a specific user by user name or invite other users to join through social media.'
            },
            {
                image: require('../../Assets/Castly/5.png'),
                description: 'Castlly message screen design to send images, video and text messages. If the receiver sees a message within the sender set time interval then the receiver will get coins otherwise sender will get coins. Users can also audio or video call another user.'
            },
            {
                image: require('../../Assets/Castly/6.png'),
                description: 'Users can change the user name and full name from profile settings, change the email or delete account setting could be accessed from an account, user can unblock already blocked users and send administrations feedback from the settings screen.'
            },
        ],
        videoUrl : require('../../Assets/Castly/castly.mp4'),
        detailPageBgColor: '#cb58edb3'
    },
    
    // {
    //     name: 'BChat',
    //     description: 'BChat project include iOS application to provide social media and news platform for Dakar Rally participants.',
    //     detailPageTitle: 'What’s BChat:',
    //     detailDescription1: 'BChat is a social media application specially design and developed for Dakar Rally. It provides all latest news related to rally and connect participants. User could find other users based on number and send text, image, video and location messages, it uses industry standard 256 Bit AEP Encryption to keep your information safe & secure. Users could create group, add participant and share their group to other apps. Broadcast can be used to subscribe your favourite celebrity or create your own broadcast.',
    //     logoImage: require('../../Assets/BChat/logo.png'),
    //     // waveIconImage: require('../../Assets/MerchantNation/Green.png'),
    //     appStoreLink: 'https://apps.apple.com/pk/app/bchat-secure-app/id1448557391',
    //     websiteLink: 'https://b-chat.app',
    //     playStoreLink : 'https://play.google.com/store/apps/details?id=com.bchat.app',
    //     bannerBackgroundColor: '#C5CB28',
    //     clientImageBorder: '4px solid  #C5CB28',
    //     sliderData: [
    //         {
    //             image: require('../../Assets/BChat/1.png'),
    //             description: '1)	News feed is design to publish latest news which could be a post containing only text, image, video, image with text and video with text. User could view image or video on full screen by taping on it and link is provided to share news with other applications. Filters could be applied to only view specific type of news feed.'
    //         },
    //         {
    //             image: require('../../Assets/BChat/2.png'),
    //             description: '2)	BChat provide encrypted secure chat feature. User can message to other users which exists on contacts and using BChat application. Chat screen list all recent chats with last message and time to last message.'
    //         },
    //         {
    //             image: require('../../Assets/BChat/3.png'),
    //             description: '3)	Group screen list all groups where user can create new group, named it and give it desirable image. User could add other users in group, invite members though link, make admin and remove members from group settings.'
    //         },
    //         {
    //             image: require('../../Assets/BChat/4.png'),
    //             description: '4)	User can use broadcast to create one sided message broadcast feature. Broadcast could be share though link to invite more users. Broadcast screen list all created or subscribed broadcasts.'
    //         },
    //         {
    //             image: require('../../Assets/BChat/5.png'),
    //             description: '5)	Profile screen show user information with feature to edit it. User could update his name, email, profile picture and status.'
    //         },
    //     ],
    //     // videoUrl : require('../../Assets/Castly/castly.mp4'),
    //     detailPageBgColor: '#c5cb28b8'
    // }

    //     {
    //     firstText : 'Merchant Nation',
    //     secondText : 'A Place To Order Delecious Food',
    //     firstImg : wavesIcon,
    //     fourthText : 'Our development of ClassDojo’s iOS and Android apps has helped them reach a community of millions in over 180 countries.',
    //     bannerBgColor : '#7fb685',
    //     imgBorder : '4px solid #7fb685',
    //     projectGif : require('../../Assets/Merchant-Nation.gif')
    // },
    // {
    //     firstText : 'Merchant Nation',
    //     secondText : 'A Place To Order Delecious Food',
    //     firstImg : wavesIcon,
    //     fourthText : 'Our development of ClassDojo’s iOS and Android apps has helped them reach a community of millions in over 180 countries.',
    //     bannerBgColor : '#f05d5e',
    //     imgBorder : '4px solid #f05d5e',
    // },
    // {
    //     firstText : 'Merchant Nation',
    //     secondText : 'A Place To Order Delecious Food',
    //     firstImg : wavesIcon,
    //     fourthText : 'Our development of ClassDojo’s iOS and Android apps has helped them reach a community of millions in over 180 countries.',
    //     bannerBgColor : '#3281c7',
    //     imgBorder : '4px solid #3281c7',
    // }
]

export default homePageProjects