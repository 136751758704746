import React, { Component } from 'react'
import './index.css'
import Particles from 'react-particles-js';

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import RightArrow from '../../Assets/right-white-arrow.svg'
import data from './PhoneCodesData'
import particleConfig from './data'
import firebase from '../../Config/firebase';
import swal from 'sweetalert';

export default class ContactUs extends Component {

    state = {
        phoneNumber: '+92',
        showList: false,
        code: 'PK',
        error: false,
        name: '',
        email: '',
        message: '',
        country: 'Pakistan'
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleOnChange = (e) => {
        this.setState({ phoneNumber: e })
    }
    selectCountry(data) {
        const code = data.code
        const dialCode = data.dial_code
        const country = data.name
        this.setState({ phoneNumber: dialCode, code, showList: false, error: false , country })
    }
    setPhoneNumber = (e) => {
        const { phoneNumber } = this.state
        if (phoneNumber) this.setState({ phoneNumber: e.target.value, error: false })
        else this.setState({ error: true })

    }
    async sendMessage() {
        const { name , email, phoneNumber, message, country }= this.state

        const objToSent = {
            Country: country,
            Email: email,
            Message: message,
            Name: name,
            PhoneNumber: phoneNumber,
            AddedOn : new Date()
        }

        if(!email || !message || !name || !phoneNumber) return swal("All information is required",  '' , 'error');
        
        const response = await firebase.addDatabase('ContactUs' , objToSent)
        swal(response.message , "" , "success");
        
    }
    render() {
        const { showList, code, phoneNumber, error, name, email, message } = this.state
        return (
            <div>
                <div className='contact-us-bg-color'>
                    <Header showParticles={false} customHeaderContainerClass={'relative'} />
                    <Particles
                        params={particleConfig}
                        className='contact-us-particles-container'
                    />
                </div>
                <div className='contact-us-container'>
                    <h1>Contact Us</h1>
                    <p>We love when you message us. Please do!</p>
                    <input placeholder='Name*' value={name} onChange={(e)=> this.setState({ name: e.target.value })} />
                    <input placeholder='Email*' value={email} onChange={(e)=> this.setState({ email: e.target.value })} />
                    {/* <input placeholder='Phone Number*' /> */}
                    <div className='phone-num-input-container'>
                        <div>
                            <input value={phoneNumber} onChange={this.setPhoneNumber} placeholder='Phone Number*' />
                            <span>
                                <p>{phoneNumber ? code : ''}</p>
                                <i className="fa fa-chevron-down" onClick={() => this.setState({ showList: !showList })}></i>
                            </span>
                        </div>
                        <ul className={`phone-numbers-list-container ${showList ? '' : 'hide-phone-list'}`}>
                            {data.map((val , index) => {
                                return <span onClick={() => this.selectCountry(val)} style={{ cursor: 'pointer' }} key={index}>
                                    <li>{val.name}</li>
                                    <li>{val.dial_code}</li>
                                </span>
                            })}
                        </ul>
                    </div>
                    {error && <p style={{ color: 'red', fontWeight: 'bold' }}>Please Select Country First</p>}
                    {/* <input placeholder='Your Message' /> */}
                    <textarea placeholder='Your Message' value={message} onChange={(e)=> this.setState({ message: e.target.value })} />
                    <div className='send-message-btn' onClick={() => this.sendMessage()}>
                        <button>Send message</button>
                        <img src={RightArrow} alt={'Right Arrow'} />
                    </div>
                </div>
                <div>
                    <Footer
                        hide
                        hideParticles
                        footerMainContainerClasses='contact-us-footer-container'
                        footerLastPartClasses='contact-us-footer-last-part'
                    />
                </div>
            </div>
        )
    }
}
