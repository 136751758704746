const teamArr = [
    {
        userPic: require('../../Assets/team/brayden.jpeg'),
        name: 'Mick Brown',
        profession: 'CEO / Technical Lead'
    },
    {
        userPic:require('../../Assets/team/danish.jpg'),
        name: 'Danish Rehman',
        profession: 'CTO / Product Manager'
    },
    {
        userPic: require('../../Assets/team/ayesha.jpg'),
        name: 'Ayesha Sehar ',
        profession: 'Project Manager'
    },
    {
        userPic: require('../../Assets/team/Abeer.jpg'),
        name: 'Abeer Qamar',
        profession: 'User Experience Designer'
    },
    {
        userPic: require('../../Assets/team/shahrukh.jpg'),
        name: 'Syed Shahrukh Haider ',
        profession: 'Lead iOS Developer'
    },
    {
        userPic: require('../../Assets/team/ebad.png'),
        name: 'Ebad Ali ',
        profession: 'Lead Android Developer'
    },
    {
        userPic: require('../../Assets/team/faraz.jpg'),
        name: 'Faraz Jahangir',
        profession: 'Mern Stack Developer'
    },
    {
        userPic: require('../../Assets/team/mohsin.jpg'),
        name: 'Muhammad Mohsin ',
        profession: 'Android Developer'
    },
    {
        userPic: require('../../Assets/team/usman.jpg'),
        name: 'Muhammad Usman Paracha ',
        profession: 'iOS Developer'
    },
]

const flags = [
    require("../../Assets/Flags/united-states.svg"),
    require('../../Assets/Flags/canada.svg'),
    require('../../Assets/Flags/united-kingdom.svg'),
    require('../../Assets/Flags/germany.svg'),
    require('../../Assets/Flags/spain.svg'),
    require('../../Assets/Flags/india.svg'),
    require("../../Assets/Flags/ireland.svg"),
    require('../../Assets/Flags/sweden.svg'),
    require('../../Assets/Flags/puerto-rico.svg'),
    require('../../Assets/Flags/morocco.svg'),
    require('../../Assets/Flags/saudia-arabia.svg'),
    require('../../Assets/Flags/mexico.svg'),
    require("../../Assets/Flags/serbia.svg"),
    require('../../Assets/Flags/zimbabwe.svg'),
]

// const strategyData = [
//     {
//         title: 'Heading',
//         description: 'The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.'
//     },
//     {
//         title: 'Heading2',
//         description: 'The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.'
//     }, {
//         title: 'Heading3',
//         description: 'The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.'
//     }, {
//         title: 'Heading4',
//         description: 'The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.'
//     }, {
//         title: 'Heading5',
//         description: 'The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.'
//     }, {
//         title: 'Heading6',
//         description: 'The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.'
//     }, {
//         title: 'Heading7',
//         description: 'The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.'
//     },
//     {
//         title: 'Heading8',
//         description: 'The biggest challenge was to understand and also build the requirements for the platform. As the product is for tech geeks, it had to be a usable product with scalability in mind.'
//     },
// ]

export {
    teamArr,
    flags
}

