import React, { Component } from 'react'
import './index.css'
import Particles from 'react-particles-js';
import { withRouter } from 'react-router-dom'

import RightArrow from '../../Assets/right-white-arrow.svg'
import particlesConfig from './data'

class Footer extends Component {
    render() {
        const { hide, hideParticles, footerMainContainerClasses, footerLastPartClasses } = this.props
        return (
            <div className={`footer-main-container ${footerMainContainerClasses}`}>
                <div className={`${hideParticles ? 'hide' : ''}`}>
                    <Particles
                        params={particlesConfig}
                        className='footer-particles-container'
                    />
                </div>
                <div className={`custom-container footer-content-container ${hide ? 'hide' : ''}`} data-aos="fade-up" data-aos-duration="1000" >
                    <div className='footer-content'>
                        <h1>So What Do You Do</h1>
                        <p>Let's get started on your new project</p>
                    </div>
                    <div className='footer-button'>
                        <div onClick={() => this.props.history.push('/contactus')}>
                            <button>Get In Touch</button>
                            <img src={RightArrow} alt='Right Arrow' />
                        </div>
                    </div>
                </div>
                <div className={`custom-container footer-last-part ${footerLastPartClasses}`} >
                    <div className='row'>
                        <div className="col-7">
                            <p>Copyright {new Date().getFullYear()} . All rights reserved</p>
                        </div>
                        <div className="col-5 footer-social-links">
                            <a href="https://www.facebook.com/BrorTech-100209624724150/" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-facebook"></i>
                            </a>
                            <a href="https://twitter.com/BrorTech" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-twitter"></i>
                            </a>
                            {/* <a>
                                <i className="fa fa-instagram"></i>                            
                            </a> */}
                            <a href="https://join.skype.com/invite/dj4vckwMCtQe" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-skype "></i>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(Footer)