import React, { Component } from 'react'
import './index.css'
import { withRouter } from 'react-router-dom'

class ServicesContainer extends Component {
    render() {
        const { data } = this.props
        return (
            <div>
                {data.map((val, i) => (
                    <div className="services-background-image-container" data-aos="zoom-in" onClick={()=> this.props.history.push('./singleservice' , { data: val })} key={i}>
                        {/* <img src={require('../../Assets/web.jpg')} className='background-image' /> */}
                        <div className="services-bg-image" style={{ backgroundImage: `url('${val.bgImg}')` }} key={i}>
                            <div className="backdrop"></div>
                        </div>
                        <div className="services-text-container" data-aos="fade-up">
                            <h2>{val.firstText}</h2>
                            <p> {val.secondText} </p>
                            <p> {val.lastText} </p>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default withRouter(ServicesContainer)
