import React, { Component } from 'react'
import './index.css'
import Particles from 'react-particles-js';
import PortfolioContainer from '../../Components/PortfolioContainer/PortfolioContainer'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import homepageProjectsData from '../Portfolio/data.js'
import { flags , particlesConfig } from './data'

export default class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            heightSet: '',
            showParticles: false
        }

        this.updateDimensions = this.updateDimensions.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        window.addEventListener('scroll', this.updateDimensions);
    }

    updateDimensions() {
        if(window.scrollY >= 500) this.setState({ heightSet: window.scrollY, showParticles: true });
        else this.setState({ heightSet: '', showParticles: false });           
    }

    render() { 
        return (
            <div>
                <div className="homepage-main-container">
                    <Header 
                        fixedHeader = {this.state.heightSet ? true : false} 
                        animateHeader = 'header-animate' 
                        particleHeaderClasses= 'homepage-header'
                        showParticles= { this.state.showParticles }
                    />
                    <div className="homepage-container">
                        <Particles
                            params={particlesConfig}
                            className='homepage-particles-container'
                        />
                        <div className="homepage-heading-container">
                            <h1 className="font-oswald" data-aos="fade-down" data-aos-duration="1000">Turning Your Inspirations </h1>
                            <h1 className="text-center font-oswald" data-aos="fade-down" data-aos-duration="1000">into apps that make a connection!</h1>
                            <div className="text-center py-3">
                                <button className='hompage-message-btn' data-aos="fade-right" onClick={()=> this.props.history.push('/contactus')}>Get In Touch</button>
                                <button data-aos="fade-left" onClick={()=> this.props.history.push('/portfolio')}>See Our Work</button>
                            </div>
                        </div>
                    </div>
                    <h2 className="text-center my-3 font-oswald"><b>Products transform brand, Brands transform fortunes, Fortunes transform futures!</b></h2>
                    <div className='homepage-flags-container'>
                        {(flags.map((val , i) => 
                            <img src={val} width="60px" key={i} alt='Flags' />
                        ))}
                    </div>

                </div>

                <PortfolioContainer data={homepageProjectsData} limit={3} />
                <Footer />
            </div>
        )
    }
}
