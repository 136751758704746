import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import HomePage from "./Screens/HomePage/HomePage";
import Portfolio from "./Screens/Portfolio/Portfolio";
import Services from "./Screens/Services/Services";
import SinglePortfolio from "./Screens/SinglePortfolio/SinglePortfolio";
import SingleService from "./Screens/SingleService/SingleService";
import WhoAreWe from "./Screens/WhoAreWe/WhoAreWe";
import ContactUs from "./Screens/ContactUs/ContactUs";


export default function Router() {
  return (
    <BrowserRouter>
      <Route path="/" exact component={HomePage} />
      <Route path="/portfolio" exact component={Portfolio} />
      <Route path="/services" exact component={Services} />
      <Route path="/singleportfolio" exact component={SinglePortfolio} />
      <Route path="/singleservice" exact component={SingleService} />
      <Route path="/whoarewe" exact component={WhoAreWe} />
      <Route path="/contactus" exact component={ContactUs} />
    </BrowserRouter>
  );
}