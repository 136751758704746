import React, { Component } from 'react'
import './index.css'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

// const serviceDetails = [
//     {
//         heading: 'Server Selection:',
//         waveIcon : require('../../Assets/waves.svg'),
//         details: 'Server development begin with server provider selection, multiple companies provide this service like Amazon Web Services, Digital Ocean, Heroku and Google. Every company expertise in one category and other in some other there for client’s target market and number of users play vital rule to finalize one service provider.',
//         image: 'https://images.unsplash.com/photo-1483478550801-ceba5fe50e8e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
//     },
//     {
//         heading: 'Database Schema Designing:',
//         waveIcon : require('../../Assets/waves.svg'),
//         details: 'The database schema of a database is its structure that is designed to store all data into logically related collection which have relationship with each other’s. Design of schema depends on how client want to fetch and store data.',
//         image: 'https://images.unsplash.com/photo-1483478550801-ceba5fe50e8e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
//     },
//     {
//         heading: 'Application Program Interface Development:',
//         waveIcon : require('../../Assets/waves.svg'),
//         details: 'Application program interface (API) development provide a way for client to add, get, update and delete data from database. Development could be done using node.js, python or etc. Server-side languages.',
//         image: 'https://images.unsplash.com/photo-1483478550801-ceba5fe50e8e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
//     },
// ]

export default class SingleService extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { data } = this.props.location.state
        const servicesDetails  = this.props.location.state.data.servicesDetails
        
        return (
            <div>
                <Header />
                <div className="custom-container single-service-container">
                    <div className='back-nav-container mt-4'>
                        <i className="fa fa-angle-left"></i>
                        <p onClick={() => this.props.history.push('/services')}>Back To Services</p>
                    </div>
                    <div className='single-service-bg-image-container'>
                        <div style={{ backgroundImage: `url('${data.bgImg}')` }} className='single-service-bg-image'></div>
                        <h2 className='single-service-bg-image-text'>{data.firstText}</h2>
                    </div>
                    {servicesDetails &&  servicesDetails.map((data , i) => {
                        return <div className={`row  ${i === 0 || i % 2 === 0 ? '' : 'row-reverse'} single-service-main-container`} key={i}>
                            <div className='col-lg-5 col-md-4 single-service-detail-container' data-aos={`${i === 0 ||  i % 2 === 0 ? 'fade-left' : 'fade-right'}`}>
                                <div className='single-service-text-container'>
                                    <p>{data.heading}</p>
                                    {/* <img src={require('../../Assets/waves.svg')} /> */}
                                    <p>{data.details}</p>
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-8  single-service-image-container' data-aos={`${i === 0 ||  i % 2 === 0 ? 'fade-right' : 'fade-left'}`}>
                                <div className='single-service-image-bg-container'>
                                    <img src={data.image} className={`single-service-image ${i === 0 || i % 2 === 0 ? 'right-container' : 'left-container'}`} alt='Services' />
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <div className="single-service-footer-container">
                    <Footer />
                </div>
            </div>
        )
    }
}
