import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "font-awesome/css/font-awesome.min.css"
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init({
    startEvent: 'DOMContentLoaded',
    duration: 1500,
    delay: 0,
    easing: 'ease-out-quad',
    once: true,
    mirror: true,
});
window.addEventListener('DOMContentLoaded', function () {
    AOS.refreshHard();
});  

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
