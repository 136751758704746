import React, { Component } from 'react'
import './index.css'

import Header from '../../Components/Header/Header'
import ServicesContainer from '../../Components/ServicesContainer/ServicesContainer'
import Footer from '../../Components/Footer/Footer'
import servciesData from './data'

export default class Services extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Header />
                <div className='custom-container services-container'>
                    <div>
                        <h1>Services</h1>
                        <p>Some of our curated Designs that you'll fall in love</p>
                    </div>
                </div>
                <ServicesContainer data={servciesData} />
                <Footer />
            </div>
        )
    }
}
