import React, { Component } from 'react'
import './index.css'
import { withRouter } from 'react-router-dom'

import playstoreIcon from '../../Assets/playstore-icon.svg'
import appleIcon from '../../Assets/apple-icon.svg'
import webIcon from '../../Assets/web-icon.svg'
import RightArrow from '../../Assets/right-arrow.svg'

class PortfolioContainer extends Component {

    datalooping = ()=> {
        const { data , limit } = this.props
        const dataArr = []

        for(let i=0; i< data.length; i++){
            if(i === limit) break
            
            dataArr.push(<div key={i}>
                <div className='custom-container portfolio-container'>
                    <div className={`row ${i === 0 || i % 2 === 0 ? '' : 'row-reverse'}`} style={{borderBottom: '1px solid lightgray', paddingBottom: '50px'}}>
                        <div className='col-lg-6 col-12 portfolio-container-left-side align-center' data-aos={`${i === 0 || i % 2 === 0 ? 'fade-left' : 'fade-right'}`}>
                            <div className='portfolio-logo-box'>
                                <img src={data[i].logoImage} alt='Logo' />
                                <h3>{data[i].name}</h3>
                            </div>
                            {/* <h1>{'Title'}</h1> */}
                            <img src={data[i].waveIconImage} className='portfolio-container-waves-icon' alt='Wave Icon' />
                            <p className='portfolio-container-project-details margin-auto'>{data[i].description} </p>
                            <div className="portfolio-container-apps-links align-center">
                               {data[i].playStoreLink && <a className="portfolio-container-apps-links align-center" href={data[i].playStoreLink} target='_blank' rel="noopener noreferrer"   style={{color: 'black'}}>
                                    <img src={playstoreIcon} alt='Playstore Icon' />
                                    <p>Playstore</p>
                                </a>
                               }
                                {data[i].appStoreLink && <a className="portfolio-container-apps-links align-center" href={data[i].appStoreLink} target='_blank' rel="noopener noreferrer"   style={{color: 'black'}}>
                                    <img src={appleIcon} className="pl-3" alt="Appstore Icon" />
                                    <p>App store</p>
                                </a>
                                }
                                {data[i].websiteLink && <a className="portfolio-container-apps-links align-center" href={data[i].websiteLink} target='_blank' rel="noopener noreferrer"   style={{color: 'black'}}>
                                    <img src={webIcon} className="pl-3" alt='Web Icon' />
                                    <p>Website</p>
                                </a>
                                }

                            </div>
                            <div className="portfolio-container-view-project-btn margin-auto" onClick={() => this.props.history.push('/singleportfolio' , {data: data[i] , index: i})}>
                                <button>View Project</button>
                                <img src={RightArrow} alt='Right Arrow' />
                            </div>
                        </div>
                        <div className={`col-lg-6 col-12 ${i === 0 || i % 2 === 0 ? 'text-right justify-content-end' : ' text-left justify-content-start'} align-center margin-t d-flex`} data-aos={`${i === 0 || i % 2 === 0 ? 'fade-right' : 'fade-left'}`}>
                            <div className='project-video-container'>
                                <img src={require('../../Assets/Iphone-X outline.png')} className='iphone-mockup' alt='iPhone Mockup' />
                                {/* <img src={data[i].gifUrl} className='project-gif' width="100%" /> */}
                                <video className='project-gif'  autoplay='' muted loop>
                                    <source src={data[i].videoUrl} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='container-fluid'>
                    <div style={{ backgroundColor: data[i].bannerBackgroundColor }} className='row portfolio-container-banner'>
                        <img src='https://i.pinimg.com/originals/bf/a5/3b/bfa53b2488eb224410ac1edfbecb2a34.png' width='50px' style={{ border: data[i].clientImageBorder }} />
                        <div>
                            <p>“The STRV team is the standard of a strong Silicon Valley team.”</p>
                            <p className='text-center' >LIAM DON / CO-FOUNDER & CTO OF CLASSDOJO</p>
                        </div>
                    </div>
                </div> */}
            </div>)
        }
        return dataArr
    }
    render() {
        return (
            // <div>
            //     {data.map((val, i) => {
            //         return <div key={i}>
            //             <div className='custom-container portfolio-container'>
            //                 <div className={`row ${i === 0 || i % 2 === 0 ? '' : 'row-reverse'}`}>
            //                     <div className='col-lg-6 col-12 portfolio-container-left-side align-center' data-aos={`${i === 0 || i % 2 === 0 ? 'fade-left' : 'fade-right'}`}>
            //                         <div className='portfolio-logo-box'>
            //                             <img src={val.logoImage} />
            //                             <h3>{val.name}</h3>
            //                         </div>
            //                         {/* <h1>{'Title'}</h1> */}
            //                         <img src={val.waveIconImage} className='portfolio-container-waves-icon' />
            //                         <p className='portfolio-container-project-details margin-auto'>{val.description} </p>
            //                         <div className="portfolio-container-apps-links align-center">
            //                            {val.playStoreLink && <a className="portfolio-container-apps-links align-center" href={val.playStoreLink} target='_blank' style={{color: 'black'}}>
            //                                 <img src={playstoreIcon} />
            //                                 <p>Playstore</p>
            //                             </a>
            //                            }
            //                             {val.appStoreLink && <a className="portfolio-container-apps-links align-center" href={val.appStoreLink} target='_blank' style={{color: 'black'}}>
            //                                 <img src={appleIcon} className="pl-3" />
            //                                 <p>Appstore</p>
            //                             </a>
            //                             }
            //                             {val.websiteLink && <a className="portfolio-container-apps-links align-center" href={val.websiteLink} target='_blank' style={{color: 'black'}}>
            //                                 <img src={webIcon} className="pl-3" />
            //                                 <p>Website</p>
            //                             </a>
            //                             }

            //                         </div>
            //                         <div className="portfolio-container-view-project-btn margin-auto" onClick={() => this.props.history.push('/singleportfolio' , {data: val})}>
            //                             <button>View Project</button>
            //                             <img src={RightArrow} />
            //                         </div>
            //                     </div>
            //                     <div className={`col-lg-6 col-12 ${i === 0 || i % 2 === 0 ? 'text-right justify-content-end' : ' text-left justify-content-start'} align-center margin-t d-flex`} data-aos={`${i === 0 || i % 2 === 0 ? 'fade-right' : 'fade-left'}`}>
            //                         <div style={{ width: "344px", position: "relative" }}>
            //                             <img src={require('../../Assets/Iphone-X outline.png')} width="100%" />
            //                             {/* <img src={val.gifUrl} className='project-gif' width="100%" /> */}
            //                             <video className='project-gif'  autoplay='' muted loop>
            //                                 <source src={val.videoUrl} type="video/mp4" />
            //                             </video>
            //                             <img src={statusBarImg} className='status-bar' />
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className='container-fluid'>
            //                 <div style={{ backgroundColor: val.bannerBackgroundColor }} className='row portfolio-container-banner'>
            //                     <img src='https://i.pinimg.com/originals/bf/a5/3b/bfa53b2488eb224410ac1edfbecb2a34.png' width='50px' style={{ border: val.clientImageBorder }} />
            //                     <div>
            //                         <p>“The STRV team is the standard of a strong Silicon Valley team.”</p>
            //                         <p className='text-center' >LIAM DON / CO-FOUNDER & CTO OF CLASSDOJO</p>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     })
            //     }
            // </div>
            <div>
                {this.datalooping()}
            </div>
        )
    }
}

export default withRouter(PortfolioContainer)
